import { AbstractControl, ValidatorFn, AsyncValidatorFn, Validators } from "@angular/forms";

/**
 * Implementation Notes: ZxFormsValidators & zx-forms-field-errors (Error Component)
 *
 * ZxFormsValidators are custom validators for reactive forms,
 * extending Angular’s built-in Validators. They return error objects containing translation keys for error messages,
 * which are rendered by the zx-forms-field-errors component
 *
 * Error Messages: use en.json -> ZX_VALIDATION_ERRORS
 */

export const ZxFormsValidators = {
    required(translateKeyError = "ZX_VALIDATION_ERRORS.REQUIRED.GENERIC"): ValidatorFn {
        return function (control: AbstractControl) {
            const isValid = Validators.required(control) === null;
            return isValid ? null : { zxValidationRequired: { translateKeyError } };
        };
    },
    regexPattern(validRegexp: RegExp | string, translateKeyError: string): ValidatorFn {
        return function (control: AbstractControl) {
            const validationFunction = Validators.pattern(validRegexp);
            const isValid = validationFunction(control) === null;
            return isValid
                ? null
                : {
                      [translateKeyError]: {
                          zxValidationRegexPattern: { translateKeyError }
                      } as ZxRegexPatternValidationError
                  };
        };
    },
    minLength(minLength: number): ValidatorFn {
        return function (control: AbstractControl) {
            const result = Validators.minLength(minLength)(control);
            const isValid = result === null;
            return isValid ? null : { zxValidationsMinLength: { requiredLength: result.minlength.requiredLength } };
        };
    },
    duplication(getValuesToTest: () => Promise<string[]>): AsyncValidatorFn {
        return async function (control: AbstractControl) {
            const values = await getValuesToTest();
            const isDuplicate = values.indexOf(control.value) > -1;
            return isDuplicate ? { zxValidationDuplicateValue: true } : null;
        };
    }
};

export type ZxRegexPatternValidationError = {
    zxValidationRegexPattern: { translateKeyError: string };
};
